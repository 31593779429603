/** DEPENDANT ON CART.JS AND IS CALLED IN THERE **/
app.shippingMethod = function(cart) {
	var shippingMethodModule = {
		cacheDom: function(){
			this.$parent = $('.form-selector');
			this.$items = this.$parent.find('.form-selector__item');
			this.$radio = this.$parent.find('input');
			this.$shipping = $('.checkout-cart__total-row__shipping');
			this.$grandTotal = $('.checkout-cart__total-row__grand-total');
		},
		bindEvents: function(){
			this.$radio.on('change', this.render.bind(this));
		},
		init: function(){
			this.cacheDom();
			if(this.$parent.length > 0){
				this.bindEvents();
				this.render();
			}
		},
		render: function(){
			var price = this.$items.find('input[type="radio"]:checked').siblings('span')[0].innerText;
			if(this.$shipping.length > 0){
				this.$shipping[0].innerText = price;
			}
			price = price.replace('£', '');
			var shippingPrice = price == 'FREE' ? 0 : parseFloat(price);
			var cartTotal = cart.subTotal.price + shippingPrice;
			if(cart.hasOwnProperty('discount')){
				var discountAmount = cart.subTotal.price * (cart.discount.value/100);
				cartTotal = cart.subTotal.price - discountAmount + shippingPrice;
			}
			if(this.$grandTotal.length > 0){
				this.$grandTotal[0].innerText = '£' + numeral(cartTotal).format('£0.00');
			}
		}
	};
	return shippingMethodModule.init();
}