app.slider = function (options) {

	var opts = {
		parent: $('.slider'),
		step: 5,
		max: 100,
		min: 0,
		start: 0
	};

	$.extend(opts, options);

	if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			opts.parent = $(elem);
			app.slider(opts);
		});
	}

	var templates = {
		output: '<span>{{ output }}</span>\
				 <input type="hidden" value="{{ output }}" name="threshold" />'
	};

	var sliderModule = {
		events: {
			isMouseDown: false,
			isMouseMove: false
		},
		xPos: 0,
		realPos: 0,
		cacheDom: function () {
			this.$parent = opts.parent;
			this.$progress = this.$parent.find('.slider__progress');
			this.$handle = this.$parent.find('.slider__handle');
			this.$output = this.$parent.parent().find('.js-output');
			this.handleWidth = this.$handle.outerWidth();
			this.parentWidth = this.$parent.outerWidth();
		},
		bindEvents: function () {
			this.$parent.on('click', this.mousemove.bind(this));
			this.$parent.on('mousedown', this.mousedown.bind(this));
			$(document).on('mouseup', this.mouseup.bind(this));
			$(document).on('mousemove', this.mousemove.bind(this));
		},
		getMousePos: function (event, minusWidth) {
			var x = event.pageX - this.$parent.offset().left;
			var y = event.pageY - this.$parent.offset().top;
			var x = (minusWidth ? (x - (this.handleWidth/2)) : x);
			return { x: x, y: y };
		},
		setDirection: function (event) {
			if (event.pageX > this.xPos) {
				this.direction = 'right';
			} else if (event.pageX < this.xPos) {
				this.direction = 'left';
			}
			this.xPos = event.pageX;
		},
		setDefaultProgress: function () {
			if (this.$parent[0].dataset.hasOwnProperty('start')) {
				var start = parseInt(this.$parent[0].dataset.start);
				var progress = (this.$parentWidth / 100) * start;
				this.realPos = (start * this.parentWidth) / opts.max;
				this.$handle.css({ transform: 'translate3d(' + (this.realPos - (this.handleWidth/2)) + 'px, 0, 0)' });
				this.$progress.css({ width: this.realPos + 'px' });
			}
		},
 		mousedown: function (e) {
			this.events.isMouseDown = true;
			this.$handle.css({ cursor: '-webkit-grabbing' });
		},
		mouseup: function (e) {
			this.events.isMouseDown = false;
			this.$handle.css({ cursor: '-webkit-grab' });
		},
		mousemove: function (e) {
			e.preventDefault();
			this.eventType = e.type;
			if (this.events.isMouseDown || e.type === 'click') {
				this.events.isMouseMove = true;
				this.handlePos = this.getMousePos(e, true).x;
				this.progressPos = this.getMousePos(e, false).x;
				this.realPos = this.getMousePos(e, false).x;
				this.setDirection(e);
				if (this.handlePos <= 0) {
					this.handlePos = -1;
					this.progressPos = (this.handleWidth/2);
					this.realPos = 0;
				} else if (this.handlePos >= (this.parentWidth - this.handleWidth)) {
					this.handlePos = this.parentWidth - this.handleWidth;
					this.progressPos = this.parentWidth;
					this.realPos = this.parentWidth;
				}
				this.$handle.css({ transform: 'translate3d(' + this.handlePos + 'px, 0, 0)' });
				this.$progress.css({ width: this.progressPos + 'px' });
				this.render();
			}
		},
		render: function () {
			var percent = (this.realPos/this.parentWidth) * opts.max;
			var remainder = Math.round(percent % opts.step, 1);
			if (remainder === 0) {
				var template = Mustache.render(templates.output, { output: Math.round(percent, 1) });
				this.$output.html(template);
			} else if (this.eventType == 'click') {
				var template = Mustache.render(templates.output, { output: Math.ceil(percent / opts.step) * opts.step });
				this.$output.html(template);
			}
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
			this.setDefaultProgress();
			this.render();
		}
	};

	return sliderModule.init();
};