app.cardFiller = function() {
    'use strict';
    var cardFillerModule = {
        fields: ['number', 'name', 'exp_month', 'exp_year', 'cvc'],
        cacheDom: function(){
            this.$parent = $('[data-card-filler-form]');
            this.$select = $('[data-card-filler-select]');
            this.$inputs = this.$parent.find('input[type="text"]');
            this.$address = $('[data-billing-address]');
            this.$addressForm = $('[data-billing-form]');
            this.$addressToggle = $('[data-billing-toggle]');
            this.$toggle = $('[name="same_as_shipping"]');
            this.$form = $('#payment-form');
        },
        bindEvents: function(){
            if(this.$select.length > 0){
                this.$select.on('change', this.fillForm.bind(this));
            }
            for(var i = 0; this.fields.length > i; i++){
                this.$parent.find('[data-stripe="' + this.fields[i] + '"]').on('keyup', this.newCard.bind(this));
            }
            this.$toggle.on('change', this.toggleBillingForm.bind(this));
        },
        clearForm: function(){
            this.formFilled = false;
            for(var i = 0; this.fields.length > i; i++){
                this.$parent.find('[data-stripe="' + this.fields[i] + '"]')[0].value = '';
            }
            this.$address.html('');
            this.$addressToggle.show();
            this.toggleBillingForm();
        },
        fillForm: function(){
            this.formFilled = true;
            var selected = this.$select.find(':selected')[0];
            var checkboxVal = this.checkBillingForm();
            if(!checkboxVal){
                this.$toggle.click();
            }
            if(selected.dataset.id != 0){
                var card = JSON.parse(selected.dataset.properties);
                for(var i = 0; this.fields.length > i; i++){
                    if(this.fields[i] == 'number'){
                        this.$parent.find('[data-stripe="' + this.fields[i] + '"]')[0].value = '**** **** **** ' + card['last4'];
                    }
                    if(this.fields[i] == 'cvc'){
                        this.$parent.find('[data-stripe="' + this.fields[i] + '"]')[0].value = '***';
                    }else if(this.fields[i] !== 'number'){
                        this.$parent.find('[data-stripe="' + this.fields[i] + '"]')[0].value = card[this.fields[i]];
                    }
                }
                if(card.address_line1 !== null){
                    var template = Mustache.render(app.templates.cardFiller.address, card);
                    this.$address.html(template);
                    this.$addressToggle.hide();
                    this.$addressForm.hide();
                }
            }else{
                this.clearForm();
            }
        },
        newCard: function(e){
            this.formFilled = false;
            var target = e.currentTarget;
            if(this.$select.length > 0){
                var selectedOption = this.$select.find('option:selected')[0].dataset.id;
                this.$select.find('option').removeAttr('selected');
                if(target.dataset.stripe !== 'cvc' && selectedOption != 0){
                    this.$parent.find('[data-stripe="cvc"]')[0].value = '';
                }
            }
            // card number input
            // add space after every 4 digits
            if(target.dataset.stripe == 'number'){
                target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            }
            this.$address.html('');
            this.toggleBillingForm();
        },
        stripePayment: function(){
            if(this.$select.length > 0){
                var selectedOption = this.$select.find('option:selected')[0].dataset.id;
                if(selectedOption == 0){
                    Stripe.card.createToken(this.$parent, this.stripeResponseHandler);
                    return false;
                }
            }else{
                Stripe.card.createToken(this.$parent, this.stripeResponseHandler);
            }
            return false;
        },
        stripeResponseHandler: function(status, response){
            if (response.error) {
                $('.payment-errors').addClass('form__message form__message--error form__message--full-width').text(response.error.message);
            } else {
                var form = $('#payment-form');
                form.append('<input type="hidden" name="stripeToken" value="' + response.id + '"/>');
                form.get(0).submit();
            }
        },
        toggleBillingForm: function(){
            if(this.$toggle.is(':checked')){
                this.$addressForm.hide();
            }else{
                this.$addressForm.show();
            }
        },
        checkBillingForm: function(){
            if(this.$toggle.is(':checked')){
                return true;
            }else{
                return false;
            }
        },
        init: function(){
            this.cacheDom();
            app.formValidate('[data-validate="callback"]', this);
            this.bindEvents();
            if(this.$select.length > 0){
                this.fillForm();
            }
        }
    };
    return cardFillerModule.init();
};