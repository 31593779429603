app.useUserPoints = function (options) {

	var opts = {
		parent: options.parent,
		cartTotal: options.cartTotal
	};

	$.extend(opts, options);

	if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			if (opts.parent.length > 0) {
				opts.parent = $(elem);
				app.useUserPoints(opts);
			}
		});
	}

	var templates = {
		points: '{{ points }}'
	};

	var useUserPointsModule = {
		pointsToUse: 0,
		cartTotal: options.cartTotal,
		cacheDom: function () {
			this.$parent = opts.parent;
			this.$input = this.$parent.find('.user__points__amount').find('input');
			this.$points = this.$parent.find('.user__points__amount').find('span');
			this.$increment = this.$parent.find('.user__points__increment');
			this.$decrement = this.$parent.find('.user__points__decrement');
			this.$usePoints = this.$parent.find('.js-use-points');
			this.$cartTotal = this.$parent.find('.js-cart-total');
		},
		bindEvents: function () {
			this.$increment.on('click', this.updatePoints.bind(this, 'increment'));
			this.$decrement.on('click', this.updatePoints.bind(this, 'decrement'));
			this.$usePoints.on('click', this.usePoints.bind(this));
		},
		getToken: function () {
			return $('meta[name="csrf-token"]').attr('content');
		},
		getPoints: function () {
			var self = this;
			$.ajax({
				type: 'GET',
				url: '/user/get-points',
				headers: { 'X-CSRF-TOKEN': self.getToken() },
				success: function(points){
					self.cacheDom();
					self.bindEvents();
					self.maxPoints = parseInt(points);
					self.pointsToUse = parseInt(points);
				},
				error: function(data){}
			});
		},
		updatePoints: function (type, e) {
			switch (type) {
				case 'increment':
					if (this.pointsToUse < this.maxPoints) {
						this.pointsToUse++;
					} else if (this.pointsToUse === this.maxPoints-1){
						this.$increment.attr('disabled', true);
					}
					break;
				case 'decrement':
					if (this.pointsToUse > 1) {
						this.pointsToUse--;
					} else if (this.pointsToUse === 1){
						this.$decrement.attr('disabled', true);
					}
					break;
			}
			this.render();
		},
		usePoints: function () {
			var self = this;
			$('.modal').removeClass('modal--active');
			$('.overlay').removeClass('overlay--active');
			$('body').removeClass('no-scroll');
			$.ajax({
				type: 'POST',
				url: '/cart/use-points',
				headers: { 'X-CSRF-TOKEN': self.getToken() },
				data: {
					pointsToUse: self.pointsToUse,
				},
				success: function(response){
					self.renderMessage(response.message, response.success);
					if (response.success) {
						location.reload();
					}
				},
				error: function(data){}
			});
		},
		render: function () {
			var points = Mustache.render(templates.points, { points: this.pointsToUse});
			var cartCouldBe = Mustache.render('{{ total }}', { total: ( this.cartTotal - this.pointsToUse )});
			this.$points.html(points);
			this.$cartTotal.html(cartCouldBe);
			this.$input[0].value = points;
		},
		renderMessage: function (message, error) {
			if (!error) {
				var errorDiv = '<div data-element="cart-error" class="message message--global message--error">\
								<div class="message__header">\
									' + message + '\
								</div>\
							</div>';
			} else {
				var errorDiv = '<div data-element="cart-error" class="message message--global message--success">\
								<div class="message__header">\
									' + message + '\
								</div>\
							</div>';
			}
			$('body').append(errorDiv);
			setTimeout(function(){
				$('[data-element="cart-error"]').addClass('message--hide');
			}, 5000);
		},
		init: function () {
			this.getPoints();
		},
	};

	return useUserPointsModule.init();
};