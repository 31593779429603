var app = app || {
	store: {
		images: {},
		newletterClosed: false,
	}
};
//globals
$(document).on('change', 'input[type="checkbox"]', function() {
	if($(this).is(':checked')){
		$(this).parents('.toggle').addClass('toggle--active');
	}else{
		$(this).parents('.toggle').removeClass('toggle--active');
	}
});

$(document).ready(function() {
	$('.modal[data-modal="newsletter-signup"]').find('.modal__close').on('click', function() {
		app.store.newletterClosed = true;
		localStorage.setItem('newletterClosed', true);
	});
});

$(document).on('mouseleave', function(e){
	var hasClosed = localStorage.getItem('newletterClosed');
    if( e.clientY < 0
    	&& window.location.pathname !== '/my-account'
    	&& window.location.pathname.indexOf('/checkout/') <= -1
    	&& window.location.pathname.indexOf('/admin') <= -1
    	&& !app.store.newletterClosed
    	&& hasClosed != 'true'){
        	$('.overlay').addClass('overlay--active').attr('data-is-newsletter', true);
        	$('.overlay').off();
        	$('.modal[data-modal="newsletter-signup"]').addClass('modal--active');

    }
});