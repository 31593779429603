app.addressFiller = function() {
    'use strict';
    var addressFillerModule = {
        fields: [
            'company',
            'address_line_1',
            'address_line_2',
            'city',
            'postcode',
            'country'
        ],
        templates: {
            newAddressInput: '<input type="hidden" name="new_address" value="true" />',
            saveAddress: '\
                <div data-save-address-toggle class="form__row">\
                    <b>Save address?</b>\
                    <div class="toggle">\
                        <label>\
                            <input name="save_new_address" type="checkbox" value="0">\
                            <div class="toggle__content">\
                                <div class="toggle__button"></div>\
                            </div>\
                        </label>\
                    </div>\
                </div>'
        },
        cacheDom: function(){
            this.$parent = $('[data-address-filler-form]');
            this.$select = $('[data-address-filler-select]');
            this.$inputs = this.$parent.find('input[type="text"]');
        },
        clearForm: function(){
            this.$parent.append(this.templates.newAddressInput);
            this.$parent.append(this.templates.saveAddress);
            for(var i = 0; this.fields.length > i; i++){
                this.$parent.find('[name="' + this.fields[i] + '"]')[0].value = '';
            }
        },
        fillForm: function(){
            var selected = this.$select.find(':selected')[0];
            $('[name="new_address"]').remove();
            $('[data-save-address-toggle]').remove();
            if(selected.dataset.id != 0){
                var address = JSON.parse(selected.dataset.properties);
                for(var i = 0; this.fields.length > i; i++){
                    this.$parent.find('[name="' + this.fields[i] + '"]')[0].value = address[this.fields[i]];
                }
            }else{
                this.clearForm();
            }
        },
        newAddress: function(){
            this.$select.find('option').removeAttr('selected');
            if($('[name="new_address"]').length === 0){
                this.$parent.append(this.templates.newAddressInput);
            }
            if($('[data-save-address-toggle]').length === 0){
                this.$parent.append(this.templates.saveAddress);
            }
        },
        bindEvents: function(){
            this.$select.on('change', this.fillForm.bind(this));
            this.$inputs.on('keyup', this.newAddress.bind(this));
        },
        init: function(){
            this.cacheDom();
            this.bindEvents();
            if(this.$select.length > 0){
                this.fillForm();
            }
        }
    };
    return addressFillerModule.init();
};