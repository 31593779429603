//
// SET CART TEMPLATES OBJECT
//
app.templates = {};

//CART
app.templates.cart = {};

//CARD FILLER
app.templates.cardFiller = {};

//...