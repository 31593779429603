app.offscreen = function(){
    var offscreenModule = {
        cacheDom: function(){
            this.$body = $('body');
            this.$parent = $('.off-screen');
            this.$dataAttr = this.$parent.attr('data-off-screen');
            this.$open = $('a[data-off-screen="nav-mobile"]');
            this.$overlay = $('.overlay');
        },
        bindEvents: function(){
            this.$open.on('click', this.openScreen.bind(this));
            this.$overlay.on('click', this.closeScreen.bind(this));
        },
        closeScreen: function(){
            this.$body.removeClass('no-scroll');
            this.$parent.removeClass('off-screen--active');
            this.$overlay.removeClass('overlay--active');
        },
        openScreen: function(){
            this.$body.addClass('no-scroll');
            this.$parent.addClass('off-screen--active');
            this.$overlay.addClass('overlay--active');

        },
        init: function(){
            this.cacheDom();
            this.bindEvents();
        },
    }
    return offscreenModule.init();
};
app.offscreen();