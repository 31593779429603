app.variations = function (options) {
	
	'use strict';
	
	var opts = {
		parent: $('[data-parent="add-variations"] .colour-selector__colours'),
		colours: []
	};

	$.extend(opts, options);

	if ($(opts.parent).length > 1) {
		return $(opts.parent).each(function (i, elem) {
			return app.variations({
				parent: $(elem)
			});
		});
	}

	var templates = {
		colour: '{{#colours}}\
					<div class="tooltip">\
						<div data-colour="{{id}}" style="background-color: {{hex}}" class="colour-selector__colour {{#isSelected}}colour-selector__colour--checked{{/isSelected}} {{#isActive}}colour-selector__colour--selected{{/isActive}}">\
							<input type="hidden" name="newColours[{{i}}]" />\
							<input value="{{hex}}" type="hidden" name="newColours[{{i}}][hex]" />\
							{{#hasName}}\
								<input value="{{name}}" type="hidden" name="newColours[{{i}}][name]" />\
							{{/hasName}}\
						</div>\
						<div class="tooltip__content">\
							<input data-id="{{id}}" placeholder="colour name.." class="colour-selector__colour__name-input" type="text" {{#hasName}}value="{{name}}"{{/hasName}} />\
						</div>\
					</div>\
				{{/colours}}',
		size: 	'<div class="size-selector__size">\
					<span>{{name}}</span>\
					<input type="text" placeholder="stock" />\
				</div>',
		tab: 	'{{#colours}}\
					<div data-id="{{id}}" class="arrow-tabs__tab" {{#isActive}}style="display: block;"{{/isActive}}>\
						<div class="arrow-tabs__tab__arrow" style="left: {{arrowPos}}px;"></div>\
						<div class="arrow-tabs__tab__actions">\
							<div class="toggle toggle--mini">\
								<label>\
									<input name="default_billing" type="checkbox" {{#isSelected}}checked="checked"{{/isSelected}} value="0">\
									<div class="toggle__content">\
										<div class="toggle__button"></div>\
									</div>\
								</label>\
							</div>\
						</div>\
						<h3>{{#hasName}}{{name}}{{/hasName}}{{^hasName}}No name added{{/hasName}}</h3>\
						<div class="grid grid--no-margin">\
							<div class="grid__item grid__item--6">\
								<div data-colour="{{#hasName}}{{name}}{{/hasName}}{{^hasName}}No name added{{/hasName}}" class="file-uploader">\
									<label for="file-uploader-{{name}}">\
										<div data-files class="grid grid--no-margin"></div>\
										<div class="loader">\
											<div class="loader__circle"></div>\
										</div>\
									</label>\
									<input class="file-uploader__input" id="file-uploader-{{name}}" multiple type="file" name="product_images[{{name}}][]"/>\
								</div>\
							</div>\
							<div class="grid__item grid__item--6">\
								<div class="size-selector__sizes">\
									{{#sizes}}\
										<div class="size-selector__size">\
											<span>{{name}}</span>\
											<input type="text" placeholder="stock" />\
										</div>\
									{{/sizes}}\
									<div class="size-selector__size--add">\
										<input type="text" placeholder="new size.." />\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
				{{/colours}}'
	};

	var variationsModule = {
		newColours: [],
		newSizes: [],
		sizes: opts.sizes,
		colours: opts.colours,
		cacheDom: function () {
			this.$parent = $(opts.parent);
			this.$tabClose = this.$parent.find('.arrow-tabs__tab__close');
			this.$colours = this.$parent.find('.colour-selector__colour');
			this.$addColour = this.$colours.find('input');
			this.$newColourParent = this.$parent.find('.colour-selector__new-colours');
			this.$sizes = this.$parent.find('.size-selector__size');
			this.$addSize = this.$parent.find('.size-selector__size--add');
			this.$save = $('.save-variations');
			this.$savedColoursParent = $('.colour-selector__saved-colours');
		},
		bindEvents: function () {
			this.$parent.on('click', '.colour-selector__colour', this.showTab.bind(this));
			this.$addColour.on('click', this.addColour.bind(this));
			this.$addColour.on('input', this.changeColour.bind(this));
			this.$parent.on('click', '.toggle', this.toggleColour.bind(this));
			this.$parent.on('change', '.colour-selector__colour__name-input', this.changeColourName.bind(this));
			this.$parent.on('click', '.size-selector__size', this.toggleSize.bind(this));
			this.$parent.on('keyup', '.size-selector__size--add', this.addSize.bind(this));
			this.$parent.on('keyup', '.size-selector__size input', this.addStock.bind(this));
		},
		getColour: function (array, id) {
			return array.filter(function (item, i) {
				if (item.id == id) {
					return item;
				}
			});
		},
		getSelectedColours: function () {
			var colours = this.colours.concat(this.newColours);
			var selectedColours = colours.filter(function (item, i) {
				if (item.isSelected) {
					return item;
				}
			});
			app.store.selectedColours = selectedColours;
			return selectedColours;
		},
		getColours: function () {
			if (this.colours.length > 0 && this.newColours.length > 0) {
				var colours = this.colours.concat(this.newColours);
			} else if (this.newColours.length > 0) {
				var colours = this.newColours;
			} else if (this.colours.length > 0) {
				var colours = this.colours;
			} else {
				var colours = [];
			}
			return colours;
		},
		setColourToActive: function (array, colour) {
			array.map(function (item, i) {
				if (colour.id == item.id) {
					item.isActive = true;
				} else {
					item.isActive = false;
				}
			});
		},
		showTab: function (e) {
			var tabId = e.currentTarget.dataset.colour;
			var colourIndex = $(e.currentTarget.parentElement).index();
			var colours = this.getColours();
			// if ($(e.currentTarget.parentElement.parentElement).hasClass('colour-selector__new-colours')) {
			// 	colourIndex += opts.colours.length;
			// }
			var colourWidth = $(e.currentTarget).outerWidth();
			var colourHalf = colourWidth/2;
			var arrowPos = colourWidth * (colourIndex + 1) - (colourHalf);
			if (colourIndex > 0) {
				arrowPos += 5*colourIndex;
			}
			this.$parent.find('.arrow-tabs__tab').hide();
			this.$parent.find('.arrow-tabs__tab[data-id="' + tabId + '"]').show();
			this.$parent.find('.arrow-tabs__tab__arrow').css('left', arrowPos + 'px');
			this.$parent.find('.colour-selector__colour').removeClass('colour-selector__colour--selected');
			this.$parent.find(e.currentTarget).addClass('colour-selector__colour--selected');
			if (colours.length > 0) {
				var colour = this.getColour(colours, tabId);
			} else {
				return;
			}
			this.currentTab = tabId;
			if (colours.length > 0) {
				colour[0].arrowPos = arrowPos;
				this.setColourToActive(colours, colour[0]); 
			}
		},
		toggleColour: function (e) {
			var colourElem = this.$parent.find('[data-colour="' + this.currentTab + '"]');
			var colours = this.getColours();
			if (colours.length > 0) {
				var colour = this.getColour(colours, this.currentTab);
			} else {
				return;
			}
			if (e.target.checked) {
				colourElem.addClass('colour-selector__colour--checked');
				colour[0].isSelected = true;
			} else {
				colourElem.removeClass('colour-selector__colour--checked');
				colour[0].isSelected = false;
			}
			this.selectedColours = this.getSelectedColours();
		},
		addColour: function (e) {
			var colours = this.getColours();
			if (colours.length > 0) {
				var lastItem = colours[(colours.length - 1)];
				var currentId = (colours.length + 1) + parseInt(lastItem.id);
			} else {
				var currentId = 1;
			}
			this.newColours.push({
				'hex': '#000',
				'id': currentId,
				'i': (this.newColours.length),
				'hasName': false,
				'selectedSizes': []
			});
			this.currentId = currentId;
			this.render();
		},
		changeColour: function (e) {
			var hex = e.currentTarget.value;
			var colour = this.getColour(this.newColours, this.currentId);
			colour[0].hex = hex;
			this.render();
		},
		changeColourName: function (e) {
			var name = e.currentTarget.value;
			this.currentId = e.currentTarget.dataset.id;
			var colour = this.getColour(this.newColours, this.currentId);
			colour[0].name = name;
			colour[0].hasName = true;
			this.render();
		},
		toggleSize: function (e) {
			if (e.target.nodeName === 'INPUT') {
				return;
			}
			this.$parent.find(e.currentTarget).toggleClass('size-selector__size--selected');
			this.$parent.find(e.currentTarget).find('input').focus();
			var colours = this.getColours();
			var colourId = e.currentTarget.offsetParent.dataset.id;
			var colour = this.getColour(colours, colourId);
			var sizeName = $(e.currentTarget).find('span')[0].innerText;
			if (this.$parent.find(e.currentTarget).hasClass('size-selector__size--selected')) {
				colour[0].selectedSizes.push({
					name: sizeName
				});
			} else {
				colour[0].selectedSizes = colour[0].selectedSizes.filter(function (item, i) {
					if (item.name != sizeName) {
						return item;
					}
				});
			}
		},
		addSize: function (e) {
			if (e.keyCode === 13 && e.target.value !== '') {
				var sizesParent = this.$parent.find('.arrow-tabs__tab');
				for (var i = 0; i < sizesParent.length; i++) {
					var parent = $(sizesParent[i]);
					var template = Mustache.render(templates.size, {name: e.target.value});
					$(template).insertBefore($(parent).find('.size-selector__size--add'));
				}
				this.sizes.push({name: e.target.value});
				this.newSizes.push({name: e.target.value});
				e.target.value = '';
				return false;
			}
		},
		addStock: function (e) {
			var colours = this.getColours();
			var colourId = e.currentTarget.offsetParent.dataset.id;
			var colour = this.getColour(colours, colourId);
			var sizeName = $(e.currentTarget.parentElement).find('span')[0].innerText;
			colour[0].selectedSizes.map(function (item, i) {
				if (item.name == sizeName) {
					item.quantity = parseInt(e.currentTarget.value);
				}
			});
		},
		render: function () {
			var colourTemplate = Mustache.render(templates.colour, {
				colours: this.newColours
			});
			var tabTemplate = Mustache.render(templates.tab, {
				colours: this.newColours,
				sizes: this.sizes
			});
			this.$newColourParent.html(colourTemplate);
			$('.arrow-tabs__new-tabs').html(tabTemplate);
			this.selectedColours = this.getSelectedColours();
			app.fileUploader({
				parent: $('.arrow-tabs__new-tabs .file-uploader')
			});
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
			$(this.$parent.find('[data-colour]')[0]).click();
		}
	};
	return variationsModule.init();
};