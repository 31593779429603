'use strict';
app.updateCollectionInfo = function(options) {

    var opts = {
        parent: $('.js-update-collection'),
    };
    
    $.extend(opts, options);

    var updateCollectionInfoModule = {
        cacheDom: function () {
            this.$parent = opts.parent;
            this.$form = this.$parent.find('form');
            this.$submit = this.$parent.find('.js-submit');
        },
        bindEvents: function () {
            this.$submit.on('click', this.submit.bind(this));
            this.$form.on('keyup keypress', 'input[type="text"]', this.cancelSubmit.bind(this));
        },
        getToken: function(){
            return $('meta[name="csrf-token"]').attr('content');
        },
        getStore: function () {
            return app.store;
        },
        getImages: function (product) {
            return app.store.images[product];
        },
        getProducts: function () {
            return Object.keys(app.store.images);
        },
        getData: function () {
            var products = this.getProducts();
            var productData = [];
            for (var i = 0; i < products.length; i++) {
                var store = this.getStore();
                productData.push({
                    name: products[i],
                    images: store.images[products[i]]
                });
            }
            return productData;
        },
        addLoader: function () {
            $('.js-global-loader').addClass('loader--active');
        },
        removeLoader: function () {
            $('.js-global-loader').removeClass('loader--active');
        },
        submit: function (e) {
            e.preventDefault();
            this.addLoader();
            var self = this;
            var form = new FormData(this.$form);
            var inputs = $('[data-submit]');
            var products = this.getData();
            for (var i = 0; i < inputs.length; i++) {
                form.append('data[collectionInfo]['+inputs[i].name+']', inputs[i].value);
            }
            for (var i = 0; i < products.length; i++) {
                var product = products[i];
                form.append('data[products]['+i+'][name]', product.name);
                for(var x = 0; x < product.images.length; x++){
                    form.append('data[products]['+i+'][images]['+x+'][file]', product.images[x].file);
                }
            }
            $.ajax({
                url: this.$form[0].action,
                type: 'POST',
                headers: {'X-CSRF-TOKEN': this.getToken()},
                data: form,
                processData: false,
                cache: false,
                contentType: false,
                success: function (message, data) {
                    location.reload();
                },
                error: function(err, msg) {
                    console.log(err);
                    console.log(msg);
                }
            });
            return false;
        },
        cancelSubmit: function (e) {
            if(e.which == 13) {
                e.preventDefault();
                return false;
            }
        },
        init: function () {
            this.cacheDom();
            this.bindEvents();
            console.log(this);
        },
    };
    return updateCollectionInfoModule.init();
};