app.productGallery = function(options){

	var opts = {
		parent: $('.product__gallery')
	};

	$.extend(opts, options)

	if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			app.productGallery({
				parent: $(elem)
			});
		});
	}

	var productGalleryModule = {
		index: 1,
		cacheDom: function(){
			this.$parent = opts.parent;
			this.$mainContainer = this.$parent.find('.product__gallery__main-image');
			this.$mainImage = this.$parent.find('.product__gallery__main-image img');
			this.$thumbs = this.$parent.find('.product__gallery__thumb');
			this.$loader = this.$parent.find('.loader');
		},

		bindEvents: function(){
			this.$thumbs.on('click', this.changeImage.bind(this));
			this.$rightArrow.on('click', this.next.bind(this));
			this.$leftArrow.on('click', this.prev.bind(this));
			$(document).on('keyup', this.handleArrows.bind(this));
		},

		creatDomElems: function(){
			//elems templates
			var rightArrow = '<div class="product__gallery__main-image__right-arrow"><i class="fa fa-chevron-right"></i></div>';
			var leftArrow = '<div class="product__gallery__main-image__left-arrow"><i class="fa fa-chevron-left"></i></div>';
			var numberDisplay = '<div class="product__gallery__main-image__number-display"></div>';
			//append elems
			this.$mainContainer.append(rightArrow, leftArrow, numberDisplay);
			//cache elems
			this.$rightArrow = this.$parent.find('.product__gallery__main-image__right-arrow');
			this.$leftArrow = this.$parent.find('.product__gallery__main-image__left-arrow');
			this.$numberDisplay = this.$parent.find('.product__gallery__main-image__number-display');
		},

        addLoader: function () {
            this.$loader.addClass('loader--active');
        },

        removeLoader: function () {
            this.$loader.removeClass('loader--active');
        },

		getSlug: function(){
			var t = window.location.href,
				d = t.split('/').pop(),
				r = d.split('.').splice(0, 1).pop(),
				x = r.replace('#', '')
			return x;
		},

		next: function(){
			if(this.index !== this.$thumbs.length){
				this.index++;
			}else{
				this.index = 1;
			}
			this.render();
		},

		prev: function(){
			if(this.index !== 1){
				this.index--;
			}else{
				this.index = this.$thumbs.length;
			}
			this.render();
		},

		handleArrows: function(e){
			if(e.keyCode === 37){
				return this.prev();
			}
			if(e.keyCode === 39){
				return this.next();
			}
		},

		changeImage: function(e){
			this.index = ($(e.target).index() + 1);
			this.render();
		},

		render: function(){
			this.addLoader();
			var self = this;
			var slug = this.getSlug();
			var colour = this.$parent[0].dataset.colour.toLowerCase();
			var src	 = '/storage/'+slug+'/'+colour+'/main'+this.index+'.png';
			var image = new Image();
			this.$thumbs.removeClass('product__gallery__thumb--selected');
			$(this.$thumbs[this.index-1]).addClass('product__gallery__thumb--selected');
			image.onload = function () {
				var numberDisplay = self.index + ' / ' + self.$thumbs.length;
				self.$mainImage.attr('src', src);
				self.$numberDisplay.html(numberDisplay);
				self.removeLoader();
			};
			image.src = src;
		},

		init: function(){
			this.cacheDom();
			this.creatDomElems();
			this.bindEvents();
			this.render();
		},

	};

	return productGalleryModule.init();
};