//*0f4808dffdcc4baab413d67d657e5325*//
app.instagramFeed = function(tag){
	var instagramFeedModule = {
		cacheDom: function(){
			this.$instagram = this.$parent.find('#instafeed');
			this.$images = this.$parent.find('img');
			this.$showGallery = this.$parent.find('.instagram-feed__show-more');
			this.imageCount = this.$images.length;
		},
		checkType: function(){
			var self = this;
			var typeDiv = this.$parent.find('#instafeed')[0].dataset.feed;
			var userId = 1209973248;
			var clientId = 'c777615b88ac479abeca18abd4430b2a';
			if(typeDiv == 'user'){
				var accessToken = '1209973248.1677ed0.8da2f22a091e41079cb2f036ac58e2d9';
				this.feed = new Instafeed({
					get: 'user',
					userId: userId,
					accessToken: accessToken,
					template: '<a target="_blank" href="{{link}}" class="instagram-feed__link"><img src="{{image}}"/></a>',
					sortBy : 'random',
					resolution: 'standard_resolution',
					limit: 10,
					success: function(data){
						self.instagramData = data;
					},
				    after: function(){
				    	self.cacheDom();
				    	self.bindEvents();
						var links = self.$instagram.find('.instagram-feed__link');
						links.height(links.width());
				    }
				});
			}else if(typeDiv == 'tag'){
				var accessToken = '1209973248.ba4c844.752e6d5ba7ae465db5b3d3e4a7f9bd64';
				this.feed = new Instafeed({
					get: 'tagged',
					tagName: tag,
					accessToken: accessToken,
					template: '<a target="_blank" href="{{link}}" class="instagram-feed__link"><img src="{{image}}"/></a>',
					resolution: 'standard_resolution',
					limit: 10,
					success: function(data){
						self.instagramData = data;
					},
					error:  function(data){
						console.log(data);
					},
					after: function(){
						self.cacheDom();
						self.bindEvents();
						var links = self.$instagram.find('.instagram-feed__link');
						links.height(links.width());
					}
				});
			}
		},
		runFeed: function(){
			this.feed.run();
		},
		openGallery: function(){
			app.instagramFeedGallery(this.instagramData);
		},
		bindEvents: function(){
			this.$showGallery.on('click', this.openGallery.bind(this));
		},
		init: function(){
			this.$parent = $('.instagram-feed');
			this.checkType();
			this.runFeed();
			console.log(this);
		},
	};
	return instagramFeedModule.init();
};