app.showMore = function(items){
	var showMoreModule = {
		itemsPerPage: 2,
		template:  '<div class="show-more" style="display: none;">\
						<div class="show-more__items">{{#template.items}}{{item}}{{/template.items}}</div>\
				   	</div>\
				   	<div class="show-more__buttons">\
				   		<a href="javascript:;" class="show-more__more">Show more (+{{quantity}})</a>\
				   		<a style="display:none;" href="javascript:;" class="show-more__less">Show less (-{{quantity}})</a>\
				   	</div>',
		getData: function(){
			return {
				quantity: this.$itemsCount - this.itemsPerPage,
				template: {
					items: []
				}
			};
		},
		cacheDom: function(){
			this.$items = $(items);
			this.$itemsCount = this.$items.length;
			this.data = this.getData();
		},
		editDom: function(){
			for(var i = 0; this.$itemsCount > i; i++){
				if(i >= this.itemsPerPage){
					this.data.template.items.push({
                    	'i': i,
                    	item: this.$items[i].outerHTML
                	});
					$(this.$items[i]).remove();
				}
			}
		},
		showExtraContent: function(e){
			$('.show-more').show();
			$(e.currentTarget).hide();
			$('.show-more__buttons').find('.show-more__less').show();
		},
		hideExtraContent: function(e){
			$('.show-more').hide();
			$(e.currentTarget).hide();
			$('.show-more__buttons').find('.show-more__more').show();
		},
		bindEvents: function(){
			$(document).on('click', '.show-more__more', this.showExtraContent.bind(this));
			$(document).on('click', '.show-more__less', this.hideExtraContent.bind(this));
		},
		render: function(){
            var output = Mustache.to_html(this.template, this.data);
            this.$items.parent().append(output);
            var html = $('.show-more__items').text();
            $('.show-more__items').html(html);
		},
		init: function(){
			this.cacheDom();
			if(this.$itemsCount <= this.itemsPerPage){
				return;
			}
			this.editDom();
			this.bindEvents();
			this.render();
		},
	};
	return showMoreModule.init();
};