app.imageUpload = function (options) {

	var opts = {
		parent: $('[data-upload]'),
		returnAPI: false,
	};

	$.extend(opts, options);

	if (opts.parent.length > 1 && !opts.returnAPI) {
		return opts.parent.each(function(i, elem){
			app.imageUpload({
				parent: $(elem),
			});
		});
	}

	var imageUploadModule = {
		files: [],
		cacheDom: function () {
			this.$parent = opts.parent;
		},
		bindEvents: function (elem, index, callback) {
			if (elem) {
				this.$parent = $(elem);
				this.uploadCallback = callback;
				this.uploadCallbackIndex = index;
				this.$parent.find('input').on('change', this.handleImageUpload.bind(this));
			} else {
				this.$parent.find('input').on('change', this.handleImageUpload.bind(this));
			}
		},
		handleImageUpload: function (event) {
			var elem = event.currentTarget;
            this.files = [];
            for (var i = 0; i < elem.files.length; i++) {
                this.files.push({
                    file: elem.files[i],
                });
            }
            this.renderImage();
		},
		renderImage: function () {
			var self = this;
            var fileReader = new FileReader();
            fileReader.readAsDataURL(this.files[0].file);
            fileReader.onloadend = function (e) {
                var src = e.target.result;
                var image = self.$parent.find('img');
                image[0].src = src;
                image.show();
                if (self.uploadCallback) self.uploadCallback(src, self.uploadCallbackIndex);
            };
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
		},
	};

	if (opts.returnAPI) {
		return {
			bindImageUpload: imageUploadModule.bindEvents.bind(imageUploadModule),
		};
	} else {
		return imageUploadModule.init();
	}
};