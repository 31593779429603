app.cart = function(options){
    'use strict';
    var defaults = {
        parentPrefix: 'side-cart__',
        parentClass: 'side-cart',
    };
    $.extend(defaults, options);
    defaults.subTotalElement = $('.' + defaults.parentPrefix + 'total-row__sub-total');
    defaults.quantityElement = $('.' + defaults.parentPrefix + 'quantity');
    defaults.grandTotalElement = $('.' + defaults.parentPrefix + 'total-row__grand-total');
    defaults.shippingElement = $('.' + defaults.parentPrefix + 'total-row__shipping');
    var cartModule = {
        subTotalTemplate: '{{subTotal.formatted}}',
        getToken: function(){
            return $('meta[name="csrf-token"]').attr('content');
        },
        cacheDom: function(){
            this.$product = $('.product');
            this.$productButton = this.$product.find('.product__add-to-basket');
            this.$productPrice = this.$product.find('.product__price');
            this.$productVariations = this.$product.find('.product__variations');
            this.$sideCart = $('.' + defaults.parentClass + ' .' + defaults.parentPrefix + 'content[data-items]');
            this.$subTotal = defaults.subTotalElement;
            this.$quantityElem = defaults.quantityElement;
            this.$grandTotal = defaults.grandTotalElement;
            this.$shipping = defaults.shippingElement;
        },
        bindEvents: function(){
            this.$productButton.on('click', this.addToCart.bind(this));
            this.$sideCart.on('click', '.' + defaults.parentPrefix + 'item__increment', this.increment.bind(this));
            this.$sideCart.on('click', '.' + defaults.parentPrefix + 'item__decrement', this.decrement.bind(this));
            this.$sideCart.on('click', '.' + defaults.parentPrefix + 'item__remove', this.remove.bind(this));
        },
        getCart: function(){
            var self = this;
            $.ajax({
                type: 'GET',
                datatype: 'JSON',
                url: '/cart/get-cart',
                headers: {'X-CSRF-TOKEN': self.getToken()},
                success: function(data){
                    self.renderCart(JSON.parse(data));
                    app.cart.runQueuedCallbacks('load', JSON.parse(data));
                },
                error: function(data){

                }
            });
        },
        addLoader: function(addToBasket){
            $('body').addClass('no-scroll');
            $('.' + defaults.parentPrefix + 'loader-container').addClass(defaults.parentPrefix + 'loader-container--active');
            if (addToBasket) {
                $('.loader[global]').addClass('loader--active');
            }
        },
        removeLoader: function(){
            $('body').removeClass('no-scroll');
            $('.' + defaults.parentPrefix + 'loader-container').removeClass(defaults.parentPrefix + 'loader-container--active');
            $('.loader').removeClass('loader--active');
        },
        updateCart: function(cart, url){
            var self = this;
            $.ajax({
                type: 'POST',
                datatype: 'JSON',
                url: url,
                headers: {'X-CSRF-TOKEN': self.getToken()},
                data: cart,
                success: function(data){
                    self.removeLoader();
                    self.renderCart(JSON.parse(data));
                },
                error: function(data, e, r){
                    self.removeLoader();
                    self.renderErrors(JSON.parse(data.responseText));
                }
            });
        },
        addToCart: function(e){
            var colour = this.$productVariations.find('.colour-selector__colour--selected')[0].dataset.colour;
            var size = this.$productVariations.find('[data-colour="' + colour + '"]').find('select option:selected').val();
            var price = parseFloat(this.$product.find('.product__price')[0].innerText.replace('£', ''), 2);
            var item = {
                product_id: this.$productButton[0].dataset.productId,
                product_quantity: 1,
                product_size: size,
                product_colour: colour,
                name: this.$product.find('.product__name')[0].innerText,
                price: price,
            };
            this.addLoader(true);
            this.updateCart(item, '/cart/add-to-cart');
            app.cart.runQueuedCallbacks('add', item);
        },
        increment: function(e){
            var parent = $(e.currentTarget).parents('.' + defaults.parentPrefix + 'item');
            var itemId = parent[0].dataset.itemId;
            var productId = parent[0].dataset.productId;
            var qty = parent.find('.' + defaults.parentPrefix + 'item__qty')[0].innerText;
            var colour = parent.find('.' + defaults.parentPrefix + 'item__colour')[0].innerText;
            var size = parent.find('.' + defaults.parentPrefix + 'item__size')[0].innerText;
            var item = {
                item_id: itemId,
                product_quantity: ++qty,
                product_id: productId,
                product_colour: colour,
                product_size: size
            };
            this.addLoader();
            this.updateCart(item, '/cart/increment');
            app.cart.runQueuedCallbacks('increment', item);
        },
        decrement: function(e){
            var parent = $(e.currentTarget).parents('.' + defaults.parentPrefix + 'item');
            var itemId = parent[0].dataset.itemId;
            var qty = parent.find('.' + defaults.parentPrefix + 'item__qty')[0].innerText;
            var item = {
                item_id: itemId,
                product_quantity: --qty,
            };
            this.addLoader();
            this.updateCart(item, '/cart/decrement');
            app.cart.runQueuedCallbacks('decrement', item);
        },
        remove: function(e){
            var parent = $(e.currentTarget).parents('.' + defaults.parentPrefix + 'item');
            var itemId = parent[0].dataset.itemId;
            var item = {
                item_id: itemId,
            };
            this.addLoader();
            this.updateCart(item, '/cart/remove-item');
            app.cart.runQueuedCallbacks('remove', item);
        },
        init: function(){
            this.cacheDom();
            this.bindEvents();
            this.getCart();
        },
        // TODO: Make this much more readable
        // TODO: Fix templating system
        renderCart: function(cartData){
            cartData.lower = function () {
                return function (text, render) {
                    return render(text).toLowerCase();
                }
            };
            $.extend(cartData, defaults);
            if(cartData.items.length > 0){
                var subTotalTemplate = this.subTotalTemplate;
                var items = Mustache.render(app.templates.cart.items, cartData);
                var subTotal = Mustache.render(subTotalTemplate, cartData);
                this.$sideCart.html(items);
                this.$subTotal.html(subTotal);
                this.$sideCart.parent().removeClass(defaults.parentClass + '--hidden');
                this.$quantityElem.show().html(cartData.items.length);
                if(this.$grandTotal.length > 0){
                    this.$grandTotal.html(cartData.grandTotal.formatted);
                    if(cartData.hasOwnProperty('shipping')){
                        if(cartData.shipping.isFree){
                            this.$shipping.html('FREE');
                        }else{
                            this.$shipping.html(cartData.shipping.formatted);
                        }
                    }
                }
                for(var i = 0; cartData.items.length > i; i++){
                    if(cartData.items[i].quantity == 1){
                        var decrement = $('.' + defaults.parentPrefix + 'item__decrement');
                        $(decrement[i]).attr('data-disabled', 'true');
                    }
                }
            }else{
                var noResultsTemplate = '<div class="no-results">\
                                            <div class="no-results__icon">\
                                                    <i class="fa fa-shopping-basket"></i>\
                                                </span>\
                                            </div>\
                                            <div class="no-results__message">Your cart is empty</div>\
                                        </div>\
                                        <a class="button button--center" href="/"><i class="fa fa-arrow-right"></i>Start shopping</a>';
                this.$sideCart.html(noResultsTemplate);
                this.$sideCart.parent().addClass('side-cart--hidden');
                this.$quantityElem.hide();
            }
            if(typeof app.shippingMethod == 'function'){
                app.shippingMethod(cartData);
            }
         },
         renderErrors: function(error){
            var errorDiv = '<div data-element="cart-error" class="message message--global message--error">\
                                <div class="message__header">\
                                    ' + error[0] + '\
                                </div>\
                            </div>';
            $('body').append(errorDiv);
            setTimeout(function(){
                $('[data-element="cart-error"]').addClass('message--hide');
            }, 5000);
         },
    };
    return cartModule.init();
};

/**
 *
 * CART CALLBACKS
 * Dynamically creates a set of callbacks
 * to allow for any part of the application to bind to
 * and access things like the cart, or item added
 *
 */
app.cart.callbacks = {};
app.cart.on = function(event, callback){
    if(!app.cart.callbacks.hasOwnProperty(event)){
        app.cart.callbacks[event] = [];
    }
    if(typeof callback == 'function'){
        app.cart.callbacks[event].push(callback);
    }
};
app.cart.runQueuedCallbacks = function(event, param){
    if(app.cart.callbacks.hasOwnProperty(event)){
        for(var i = 0; i < app.cart.callbacks[event].length; i++){
            app.cart.callbacks[event][i](param);
        }
    }
}



