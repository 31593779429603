app.share = function (options) {

	var opts = {
		parent: $('.js-share'),
	};

	$.extend(opts, options);

	if (opts.parent.length > 1) {
		return opts.parent.each(function(i, elem){
			app.share({
				parent: $(elem),
				href: opts.href,
			});
		});
	}

	var shareModule = {
		cacheDom: function () {
			this.$parent = opts.parent;
		},
		bindEvents: function () {
			this.$parent.on('click', this.share.bind(this));
		},
		share: function (event) {
			var shareType = event.currentTarget.getAttribute('data-share');
			this[shareType]();
		},
		facebook: function () {
			FB.init({
				appId: 324572841281812,
				xfbml: true,
				version: 'v2.2'
			});
			console.log(opts.href);
			FB.ui({
				display: 'popup',
				method: 'share',
				link: opts.href,
				href: opts.href,
			}, function (response) {
				console.log(response);
			});
		},
		// twitter: function () {
		// 	var href = "https://twitter.com/share?url=https%3A%2F%2Fdev.twitter.com%2Fweb%2Ftweet-button&via=twitterdev&
  // related=twitterapi%2Ctwitter&
  // hashtags=example%2Cdemo&
  // text=custom%20share%20text""
		// },
		init: function () {
			this.cacheDom();
			this.bindEvents();
		},
	};

	shareModule.init();
};