app.revealer = function(options){

	var opts = {
		parent: $('.reveal-item')
	};

	$.extend(opts, options)

  if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			app.revealer({
				parent: $(elem)
			});
		});
	}

	var revealerModule = {
		cacheDom: function(){
			this.$parent = opts.parent;
			this.$revealer = this.$parent.find('.reveal-item__reveal');
      console.log(this.$revealer);
		},

		bindEvents: function(){
			this.$revealer.on('click', this.reveal.bind(this));
		},

		reveal: function($event){
      // var changer = $event.closest('.reveal-item__changer');
      console.log($event);
		},

    init: function(){
      this.cacheDom();
      this.bindEvents();
    },

	};

	return revealerModule.init();
};
