'use strict';
app.fileUploader = function (options) {

    var defaults = {
        isEditPage: false,
        parent: $('.file-uploader')
    };

    $.extend(defaults, options);

    if (defaults.parent.length > 1) {
        return defaults.parent.each(function (i, elem) {
            app.fileUploader({
                isEditPage: defaults.isEditPage,
                hasColour: defaults.hasColour,
                parent: $(elem)
            });
        });
    }

    var templates = {
        image: function () {
            var template = '<div data-id="{{id}}" class="file-uploader__image">\
                                <div class="file-uploader__image__selector">\
                                    <div class="file-uploader__image__selector__name"></div>\
                                </div>\
                                <a href="javascript:;" class="file-uploader__image__remove">\
                                    <i class="fa fa-remove"></i>\
                                </a>\
                                <img src="{{src}}" />\
                                <div class="file-uploader__image__name">{{name}}</div>\
                            </div>';
            return template;
        }
    };

    var availableFiles = [
        {name: 'main1', display: 'Main 1', thumb: true, selected: false},
        {name: 'main2', display: 'Main 2', thumb: true, selected: false},
        {name: 'main3', display: 'Main 3', thumb: true, selected: false},
        {name: 'main4', display: 'Main 4', thumb: true, selected: false},
        {name: 'main5', display: 'Main 5', thumb: true, selected: false},
        {name: 'cart-image', display: 'Cart', thumb: false, selected: false},
        {name: 'main-image', display: 'Main', thumb: false, selected: false},
    ];

    var fileUploaderModule = {
        files: [],
        selected: [],
        cacheDom: function () {
            this.$parent = defaults.parent;
            this.$input = this.$parent.find('.file-uploader__input');
            this.$files = this.$parent.find('[data-files]');
            this.$loader = this.$parent.find('.loader');
            if (this.$parent.length > 0) {
                console.log(this.$parent[0]);
                this.colour = this.capitalizeFirst(this.$parent[0].dataset.colour);
            }
        },
        bindEvents: function () {
            var self = this;
            this.$input.on('change', this.upload.bind(this));
            this.$parent.on('mouseenter', '.file-uploader__image', this.hoverImage.bind(this));
            this.$parent.on('click', '.file-uploader__image', this.selectImage.bind(this));
            this.$parent.on('click', '.file-uploader__image__remove', this.removeImage.bind(this));
            //if removing/selecting image
            this.$parent.parent().on('click', function(e){
                if(
                    e.target.parentElement.className === 'file-uploader__image' ||
                    e.target.className === 'file-uploader__image__selector'
                ){
                    e.preventDefault();
                }
            });
        },
        capitalizeFirst: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        addLoader: function () {
            this.$loader.addClass('loader--active');
        },
        removeLoader: function () {
            this.$loader.removeClass('loader--active');
        },
        upload: function (e) {
            this.files = [];
            for (var i = 0; i < this.$input[0].files.length; i++) {
                this.files.push({
                    file: this.$input[0].files[i]
                });
            }
            //reset
            for (var i = 0; i < availableFiles.length; i++) {
                availableFiles[i].selected = false;
            }
            this.$input[0].value = '';
            this.addLoader();
            this.render();
        },
        find: function (array, key, prop) {
            var found;
            for (var i = 0; i < array.length; i++) {
                if(array[i].hasOwnProperty('file')){
                    if(array[i].file[key] === prop){
                        found = array[i];
                    }
                }else{
                    if(array[i][key] === prop){
                        found = array[i];
                    }                    
                }

            }
            return found;
        },
        hoverImage: function (e) {
            if($(e.currentTarget).hasClass('file-uploader__image--selected')) return;
            var $parent = $(e.currentTarget).find('.file-uploader__image__selector__name');
            for (var i = 0; i < availableFiles.length; i++) {
                if(!availableFiles[i].selected){
                    $parent.html(availableFiles[i].display);
                    break;
                }
            }
        },
        selectImage: function (e) {
            if(e.target.className === 'file-uploader__image__remove') return;
            var $parent = $(e.currentTarget);
            var name = $parent.find('.file-uploader__image__name')[0].textContent;;
            var newName = $parent.find('.file-uploader__image__selector__name')[0].textContent;
            var image = this.find(this.files, 'name', name);
            var file = this.find(availableFiles, 'display', newName);
            if(file.selected){
                image.newName = '';
                file.selected = false;
                $parent.removeClass('file-uploader__image--selected');
            }else{
                image.newName = file.name;
                file.selected = true;
                $parent.addClass('file-uploader__image--selected');
            }
            if(file.thumb){
                image.thumb = true;
            }else{
                image.thumb = false;
            }
            app.store.images[this.colour] = this.files;
        },
        removeImage: function (e) {
            var target = $(e.target).parents('.file-uploader__image')[0].dataset.id;
            this.files.splice(target, 1);
            this.addLoader();
            this.render();
        },
        appendImages: function () {
            var self = this;
            var productName = $('[name="product_name"]')[0].value;
            var slug = productName.replace(/ /g,'-').toLowerCase();
            this.addLoader();
            for (var i = 0; i < availableFiles.length; i++) {
                (function(index){
                    var urlSuffix = availableFiles[i].name;
                    var img = new Image();
                    img.crossOrigin = 'Anonymous';
                    img.src = location.origin + '/storage/' + slug + '/' + urlSuffix + '.png';
                    img.onload = function(){
                        var canvas = document.createElement('CANVAS'),
                        ctx = canvas.getContext('2d'), dataURL;
                        canvas.height = img.height;
                        canvas.width = img.width;
                        ctx.drawImage(img, 0, 0);
                        dataURL = canvas.toDataURL('png');
                        canvas = null; 
                        var data = {
                            id: index,
                            src: dataURL,
                            name: availableFiles[index].display
                        };
                        var template = Mustache.render(templates.image(), data);
                        var gridItem = document.createElement('div');
                        gridItem.className = 'grid__item grid__item--4';
                        $(gridItem).append(template);
                        self.$files.append(gridItem);
                        self.$parent.addClass('file-uploader--uploaded');
                        $(gridItem).find('.file-uploader__image').addClass('file-uploader__image--selected');
                        $(gridItem).find('.file-uploader__image__selector__name')[0].innerText = availableFiles[index].display;
                        var file = availableFiles[index];
                        file.selected = true;
                        self.removeLoader();
                    };
                })(i);
            }
        },
        render: function () {
            var self = this;
            this.$files.html('');
            for (var i = 0; i < this.files.length; i++) {
                (function(index){
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(self.files[index].file);
                    fileReader.onloadend = function (e) {
                        var data = {
                            id: index,
                            src: e.target.result,
                            name: self.files[index].file.name
                        };
                        var template = Mustache.render(templates.image(), data);
                        var gridItem = document.createElement('div');
                        gridItem.className = 'grid__item grid__item--4';
                        $(gridItem).append(template);
                        self.$files.append(gridItem);
                        self.$parent.addClass('file-uploader--uploaded');
                        self.removeLoader();
                    };
                }(i));
            }
            if (this.files.length === 0) {
                this.$parent.removeClass('file-uploader--uploaded');
                this.removeLoader();
            }
            app.store.images[this.colour] = this.files;
        },
        init: function () {
            this.cacheDom();
            this.bindEvents();
            if(defaults.isEditPage){
                this.appendImages();
            }
        },
    };
    return fileUploaderModule.init();
};