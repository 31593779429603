app.modals = function(options){
	
	'use strict';

	var opts = {
		overlayClose: true
	};

	$.extend(opts, options);

	var modalsModule = {
		cacheDom: function(){
			this.$parent = $('[data-modal]');
			this.$overlay = $('.overlay');
			this.$showModal = $('[show-modal]');
			this.$hideModal = $('.modal__close');
			this.$cancelButton = this.$parent.find('.modal__close');
			this.$agreeButton = this.$parent.find('.modal__agree');
		},

		bindEvents: function(){
			this.$cancelButton.on('click', this.cancel.bind(this));
			this.$agreeButton.on('click', this.agree.bind(this));
			$(document).on('click', '[show-modal]', this.showModal.bind(this));
			$(document).on('click', '.modal__close', this.hideModal.bind(this));
			if (opts.overlayClose) {
				$(document).on('click', '.overlay', this.hideModal.bind(this));
			}
		},

		unbindEvents: function(){
			this.$cancelButton.off('click', this.cancel.bind(this));
			this.$agreeButton.off('click', this.agree.bind(this));
		},

		showModal: function(e){
			var dataModal = $(e.currentTarget).attr('show-modal') || $(e.currentTarget).parent().attr('show-modal');
			this.$parent = $('[data-modal="' + dataModal + '"]');
			this.$parent.addClass('modal--active');
			this.$overlay.addClass('overlay--active');
			this.$overlay.css('z-index', 202);
			this.$overlay.off();
			$('body').addClass('no-scroll');
			if(this.$parent.outerHeight() == window.innerHeight){
				this.$parent.css({
					'overflow': 'auto',
					'webkit-overflow-scrolling': 'touch'
				})
			}
		},

		hideModal: function(event){
			var modalNewsletter = this.$overlay.attr('data-is-newsletter');
			if (event) {
				if (event.target.className.indexOf('overlay') > -1 && modalNewsletter == 'true'){
					return false;
				}
			}
			this.$parent.removeClass('modal--active');
			this.$parent.scrollTop(0);
			if($('.side-cart').hasClass('side-cart--active')){

			}else{
				this.$overlay.removeClass('overlay--active');
			}
			this.$overlay.removeAttr('style');
			this.unbindEvents();
			app.sideCart();
			$('body').removeClass('no-scroll');
		},

		cancel: function(){
			this.hideModal();
		},

		agree: function(){
			this.hideModal();
		},

		init: function(){
			this.cacheDom();
			this.bindEvents();
			app.hideModal = this.hideModal.bind(this);
		}
	}
	return modalsModule.init();
};