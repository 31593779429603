app.sideCart = function() {
	var sideCartModule = {
		cacheDom: function() {
			this.$body = $('body');
			this.$overlay = $('.overlay');
			this.$sidecart = $('.side-cart');
			this.$closeButton = this.$sidecart.find('.side-cart__close');
			this.$openButton = $('.side-cart__open');
		},

		closeCart: function() {
			this.$body.removeClass('no-scroll');
			this.$sidecart.removeClass('side-cart--active');
			this.$overlay.removeClass('overlay--active');
		},

		openCart: function() {
			this.$body.addClass('no-scroll');
			this.$sidecart.addClass('side-cart--active');
			this.$overlay.addClass('overlay--active');
		},

		bindEvents: function() {
			this.$openButton.on('click', this.openCart.bind(this));
			this.$closeButton.on('click', this.closeCart.bind(this));
			this.$overlay.on('click', this.closeCart.bind(this));
		},

		init: function init() {
			this.cacheDom();
			this.bindEvents();
		}
	};
	return sideCartModule.init();
};