//function to remove PHP rendered messages
app.messages = function(){
	var messagesModule = {
		time: 1500,
		errorTime: 5000,
		cacheDom: function(){
			this.$message = $('.message--global');
		},
		removeMessage: function(){
			var self = this;
			if(this.$message.length > 0){
				if(this.$message.hasClass('message--error')){
					setTimeout(function(){
						self.$message.addClass('message--hide');
					}, self.errorTime)
				}else{
					setTimeout(function(){
						self.$message.addClass('message--hide');
					}, self.time);
				}
			}
		},
		init: function(){
			this.cacheDom();
			this.removeMessage();
		}
	}
	return messagesModule.init();
};