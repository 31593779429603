app.selectTag = function (options) {

	var opts = {
		parent: $('.js-select-tags'),
	};

	$.extend(opts, options);

	if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			opts.parent = $(elem);
			app.selectTag(opts);
		});
	}

	var selectTagModule = {
		cacheDom: function () {
			this.$parent = opts.parent;
			this.$tags = this.$parent.find('.select-tag');
		},
		bindEvents: function () {
			this.$tags.on('click', this.selectTag.bind(this));
		},
		selectTag: function (e) {
			var elem = e.currentTarget;
			this.$tags.removeClass('select-tag--active');
			$(elem).addClass('select-tag--active');
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
		},
	};

	return selectTagModule.init();
};