'use strict';
var ecAnalyticsAPI = {
    addProduct: function(item) {
        ga('ec:addProduct', item);
        ga('ec:setAction', 'add');
        ga('send', 'event', 'UX', 'click', 'add to cart');
    },
    removeProduct: function(item) {
        ga('ec:addProduct', item);
        ga('ec:setAction', 'remove');
        ga('send', 'event', 'UX', 'click', 'remove from cart');
    },
    incrementProduct: function(item){
        item.quantity = 1;
        ga('ec:addProduct', item);
        ga('ec:setAction', 'add');
        ga('send', 'event', 'UX', 'click', 'increment cart item');
    },
    decrementProduct: function(item){
        item.quantity = 1;
        ga('ec:addProduct', item);
        ga('ec:setAction', 'remove');
        ga('send', 'event', 'UX', 'click', 'decrement cart item');
    }
};

app.cart.on('increment', function(item){
    ecAnalyticsAPI.incrementProduct(item);
});
app.cart.on('decrement', function(item){
    ecAnalyticsAPI.decrementProduct(item);
});
app.cart.on('add', function(item){
    ecAnalyticsAPI.addProduct(item);
});
app.cart.on('remove', function(item){
    ecAnalyticsAPI.removeProduct(item);
});