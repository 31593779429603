app.showElement = function (options) {

	var opts = {
		parent: $('.js-show-element'),
		toggle: $('.js-show-element-toggle')
	};

	$.extend(opts, options);

	if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			if (opts.parent.find(opts.toggle.selector).length > 0) {
				opts.parent = $(elem);
				app.showElement(opts);
			}
		});
	}

	var showElementModule = {
		isActive: false,
		cacheDom: function () {
			this.$parent = opts.parent;
			this.$toggle = this.$parent.find('.js-show-element-toggle');
			this.$element = this.$parent.find('.js-element-to-show');
		},
		bindEvents: function () {
			this.$toggle.on('click', this.toggleElement.bind(this));
		},
		toggleElement: function (e) {
			if (e.target.nodeName != 'DIV') { 
				return true;
			}
			if (this.isActive) {
				this.isActive = false;
			} else {
				this.isActive = true;
			}
			this.render();
		},
		render: function () {
			this.$toggle.attr('data-is-active', this.isActive);
			return this.isActive ? this.$element.show() : this.$element.hide();
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
			this.isActive = eval(this.$toggle[0].dataset.isActive);
			this.render();
		},
	};

	return showElementModule.init();
};