app.colourSelector = function() {
	'use strict';
	var colourSelectorModule = {
		cacheDom: function () {
			this.$parent = $('.colour-selector');
			this.$colours = this.$parent.find('.colour-selector__colour');
		},
		bindEvents: function () {
			this.$colours.on('click', this.changeColour.bind(this));
		},
		changeColour: function (e) {
			var colour = e.currentTarget.dataset.colour;
			if ($(e.currentTarget).hasClass('colour-selector__colour--selected')) {
				return;
			}
			$('.product__gallery[data-colour]').hide();
			$('.product__gallery[data-colour="' + colour +'"]').show();
			$('.colour-selector__colours').find('[data-colour]').removeClass('colour-selector__colour--selected');
			$('.colour-selector__colours').find('[data-colour="' + colour + '"]').addClass('colour-selector__colour--selected');
			$('.colour-selector__sizes').find('[data-colour]').hide();
			$('.colour-selector__sizes').find('[data-colour="' + colour + '"]').show();
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
			$('.product__gallery[data-colour]').hide();
			$($('.product__gallery[data-colour]')[0]).show();
			$(this.$colours[0]).addClass('colour-selector__colour--selected');
			$('.colour-selector__sizes').find('[data-colour]').hide();
			$($('.colour-selector__sizes').find('[data-colour]')[0]).show();
		},
	};

	return colourSelectorModule.init();
};