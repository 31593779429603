app.createBlogPost = function (options) {

	var opts = {
		parent: $('.blog__post'),
	};

	$.extend(opts, options);

	if (opts.parent.length > 1) {
		return opts.parent.each(function (i, elem) {
			opts.parent = $(elem);
			app.createBlogPost(opts);
		});
	}

	var templates = {
		blogBody: '\
			{{#sections}}\
				{{#isHeader}}\
					<div class="blog__row">\
						<div class="blog__row__options">\
							<a class="blog__row__options__button" data-type="reorder-up" data-index="{{index}}">\
								<i class="fa fa-caret-up" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="reorder-down" data-index="{{index}}">\
								<i class="fa fa-caret-down" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="delete" data-index="{{index}}">\
								<i class="fa fa-trash" aria-hidden="true"></i>\
							</a>\
						</div>\
						<h3 contenteditable="true" data-id="{{index}}">{{{content}}}</h3>\
					</div>\
				{{/isHeader}}\
				{{#isParagraph}}\
					<div class="blog__row">\
						<div class="blog__row__options">\
							<a class="blog__row__options__button" data-type="reorder-up" data-index="{{index}}">\
								<i class="fa fa-caret-up" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="reorder-down" data-index="{{index}}">\
								<i class="fa fa-caret-down" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="text-align-left" data-index="{{index}}">\
								<i class="fa fa-align-left" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="text-align-center" data-index="{{index}}">\
								<i class="fa fa-align-center" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="text-align-right" data-index="{{index}}">\
								<i class="fa fa-align-right" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="text-align-justify" data-index="{{index}}">\
								<i class="fa fa-align-justify" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="delete" data-index="{{index}}">\
								<i class="fa fa-trash" aria-hidden="true"></i>\
							</a>\
						</div>\
						<p style="text-align: {{ align }}" contenteditable="true" data-id="{{index}}">{{{content}}}</p>\
					</div>\
				{{/isParagraph}}\
				{{#isEmbed}}\
					<div class="blog__row">\
						<div class="blog__row__options">\
							<a class="blog__row__options__button" data-type="reorder-up" data-index="{{index}}">\
								<i class="fa fa-caret-up" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="reorder-down" data-index="{{index}}">\
								<i class="fa fa-caret-down" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="delete" data-index="{{index}}">\
								<i class="fa fa-trash" aria-hidden="true"></i>\
							</a>\
						</div>\
						<div class="blog__post__embed {{#content}}blog__post__embed--has-content{{/content}}" data-embed-id="{{embedIndex}}" style="width: {{width}}px; height: {{height}}px;">\
							<input type="text" class="blog__post__embed__input" value="{{content}}" />\
							<i class="fa fa-code"></i>\
							{{{content}}}\
						</div>\
					</div>\
				{{/isEmbed}}\
				{{#isImage}}\
					<div class="blog__row">\
						<div class="blog__row__options">\
							<a class="blog__row__options__button" data-type="reorder-up" data-index="{{index}}">\
								<i class="fa fa-caret-up" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="reorder-down" data-index="{{index}}">\
								<i class="fa fa-caret-down" aria-hidden="true"></i>\
							</a>\
							<a class="blog__row__options__button" data-type="delete" data-index="{{index}}">\
								<i class="fa fa-trash" aria-hidden="true"></i>\
							</a>\
						</div>\
						<label class="blog__post__image-upload" data-upload data-image-id="{{imageIndex}}">\
							<i class="fa fa-cloud-upload"></i>\
							<input type="file" data-input-id="{{imageIndex}}"/>\
							<img src="{{src}}" {{^src}}style="display: none;"{{/src}} data-id="{{index}}" />\
						</label>\
					</div>\
				{{/isImage}}\
			{{/sections}}\
		',
	};

	var createBlogPostModule = {
		sections: [],
		cacheDom: function () {
			this.$parent = opts.parent;
			this.$buttons = this.$parent.find('.blog__post__button');
			this.$content = this.$parent.find('.blog__post__content');
			this.$modal = this.$parent.find('.modal');
			this.$save = this.$modal.find('.button');
			this.$tooltip = this.$parent.find('.blog__tooltip');
			this.$tooltipButtons = this.$tooltip.find('button');
		},
		bindEvents: function () {
			this.$buttons.on('click', this.addSection.bind(this));
			this.$save.on('click', this.uploadImages.bind(this));
			$(document).on('blur keyup paste input', '[contenteditable]', this.addContent.bind(this));
			$(document).on('mouseup', this.handleSelection.bind(this));
			$(document).on('input', '.blog__post__embed__input', this.addEmbed.bind(this));
			$(document).on('click', '.blog__row__options__button', this.routeActions.bind(this));
			this.$tooltipButtons.on('click', this.routeActions.bind(this));
		},
		getToken: function () {
			return $('meta[name="csrf-token"]').attr('content');
		},
		getFile: function (index) {
			return $('[data-input-id="' + index + '"]')[0].files[0];
		},
		slugify: function (text) {
		  return text.toString().toLowerCase()
		    .replace(/\s+/g, '-')           // Replace spaces with -
		    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
		    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
		    .replace(/^-+/, '')             // Trim - from start of text
		    .replace(/-+$/, '');            // Trim - from end of text
		},
		addSection: function (event, injectType, file, featureType) {
			var type = event ? event.currentTarget.dataset.type : injectType;
			this.$tooltip.hide();
			switch (type) {
				case 'header':
					this.sections.push({
						isHeader: true,
						isParagraph: false,
						isImage: false,
						isEmbed: false,
						content: 'header',
						index: this.sections.length,
					});
					break;
				case 'paragraph':
					this.sections.push({
						isHeader: false,
						isParagraph: true,
						isImage: false,
						isEmbed: false,
						content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
						index: this.sections.length,
						align: 'left',
					});
					break;
				case 'embed':
					this.sections.push({
						isHeader: false,
						isParagraph: false,
						isImage: false,
						isEmbed: true,
						hasContent: false,
						content: '',
						index: this.sections.length,
					});
					break;
				case 'image':
					this.sections.push({
						isHeader: false,
						isParagraph: false,
						isImage: true,
						isEmbed: false,
						imageIndex: 0,
						index: this.sections.length,
						file: file ? file : null,
						feature: featureType ? featureType : null,
					});
					break;
			}
			if (injectType !== 'image') {
				this.render();
			}
		},
		routeActions: function (event) {
			var type = event.currentTarget.dataset.type;
			var isTextAction = type.indexOf('text-align') > -1;
			var isTooltipAction = type.indexOf('tooltip') > -1;
			var switchType = isTextAction ? 'text-align' : isTooltipAction ? 'tooltip' : event.currentTarget.dataset.type;
			var index = parseInt(event.currentTarget.dataset.index, 10);
			switch (switchType) {
				case 'reorder-up':
					this.reorderSection(index, (index - 1));
					break;
				case 'reorder-down':
					this.reorderSection(index, (index + 1));
					break;
				case 'delete':
					this.removeSection(index);
					break;
				case 'text-align':
					this.alignText(type, index);
					break;
				case 'tooltip':
					this.tooltipActions(type, index);
					break;
			}
		},
		tooltipActions: function (type, index) {
			if (type === 'tooltip-italic') {
				document.execCommand('italic', false, null);
			}
			if (type === 'tooltip-bold') {
				document.execCommand('bold', false, null);
			}
			if (type === 'tooltip-link') {
				this.createLink();
				$('[name="tooltip-link"]').val('');
			}
		},
		reorderSection: function (fromIndex, toIndex) {
			this.$tooltip.hide();
			var element = this.sections[fromIndex];
			this.sections.splice(fromIndex, 1);
			this.sections.splice(toIndex, 0, element);
			this.render();
		},
		removeSection: function (index) {
			this.sections.splice(index, 1);
			this.render();
		},
		alignText: function (type, index) {
			var align = type.replace('text-align-', '');
			$(this.sections).each(function (i, item) {
				if (item.isParagraph && index == i) {
					item.align = align;
				}
			});
			this.render();
		},
		addContent: function (event) {
			if (event.type == 'keyup') this.$tooltip.hide();
			var id = event.currentTarget.getAttribute('data-id');
			$(this.sections).each(function (i, item) {
				if (item.index == id) {
					item.content = $(event.currentTarget).html();
				}
			});
		},
		handleSelection: function (event) {
			var selection = window.getSelection();
			this.savedSelection = this.saveSelection();
			var range = selection.getRangeAt(0);
			var rect = range.getBoundingClientRect();
			var relative = document.body.parentNode.getBoundingClientRect();
			if (selection.isCollapsed) {
				this.$tooltip.hide();
			} else {
				this.$tooltip.show();
				var top = (rect.top - this.$tooltip.height() + rect.height) - ((relative.bottom*1) - $(window).height()) + 45;
				var contentHeight = 400 - this.$content.height();
				this.$tooltip.css({
					top: top - contentHeight,
					left: rect.left + (rect.width / 2),
				});
			}
		},
		saveSelection: function () {
			if (window.getSelection) {
				sel = window.getSelection();
				if (sel.getRangeAt && sel.rangeCount) {
					var ranges = [];
					for (var i = 0, len = sel.rangeCount; i < len; ++i) {
						ranges.push(sel.getRangeAt(i));
					}
					return ranges;
				}
			} else if (document.selection && document.selection.createRange) {
				return document.selection.createRange();
			}
			return null;
		},
		restoreSelection: function (savedSel) {
			if (savedSel) {
				if (window.getSelection) {
					sel = window.getSelection();
					sel.removeAllRanges();
					for (var i = 0, len = savedSel.length; i < len; ++i) {
						sel.addRange(savedSel[i]);
					}
				} else if (document.selection && savedSel.select) {
					savedSel.select();
				}
			}
		},
		createLink: function () {
			var savedSel = this.saveSelection();
			var url = $('[name="tooltip-link"]').val();
			this.restoreSelection(savedSel);
			document.execCommand('CreateLink', false, url);
			$('a[href="' + url + '"').attr('target', '_blank');
		},
		injectSrc: function (image, i) {
			var self = this;
			$(this.sections).each(function (index, item) {
				if (item.imageIndex == i) {
					var file = self.getFile(item.imageIndex);
					item.src = image;
					item.isShown = true;
					item.file = file;
				}
			});
		},
		save: function (featureImage, featureImageSquare) {
			var self = this;
			$.ajax({
				type: 'POST',
				datatype: 'JSON',
				data: {
					feature_image: featureImage,
					feature_image_square: featureImageSquare,
					title: $('.blog__post__title').text(),
					slug: this.slugify($('.blog__post__title').text()),
					content: JSON.stringify(self.sections),
					is_live: $('[name="is_live"]').is(':checked') ? 1 : 0,
					category: $('[name="blog_tag_id"]').find(":selected").val(),
				},
				url: '/blog/create-post',
				headers: { 'X-CSRF-TOKEN': self.getToken() },
				success: function(data){
					data = JSON.parse(data);
					window.location = data.redirect;
				},
				error: function(data){

				}
			});
		},
		uploadImages: function () {
			var self = this;
			var feature;
			var featureSquare;
			this.imageCount = 0;
			this.saveCount = 0;
			this.addSection(null, 'image', $('.blog__main-upload').find('input')[0].files[0], 'feature');
			this.addSection(null, 'image', $('.blog__post__image-upload--square').find('input')[0].files[0], 'featureSquare');
			this.sections.forEach(function (section, i) {
				if (section.isImage) {
					self.imageCount++;
					var form = new FormData();
					form.append('file', section.file);
					form.append('slug', self.slugify($('.blog__post__title').text()));
			        $.ajax({
			            type: 'POST',
			            datatype: 'JSON',
			            url: '/blog/create-post/upload-image',
			            data: form,
			            headers: { 'X-CSRF-TOKEN': self.getToken() },
		                processData: false,
		                cache: false,
		                contentType: false,
			            success: function(response) {
			            	var data = JSON.parse(response);
			            	section.src = data.src;
			            	self.saveCount++;
							var shouldSave = self.shouldSave();
							if (section.feature) {
								if (section.feature == 'feature') {
									feature = data.src;
								}
								if (section.feature == 'featureSquare') {
									featureSquare = data.src;
								}
							}
							if (shouldSave) {
								self.save(feature, featureSquare);
							}
			            },
			        });
				}
			});
		},
		shouldSave: function () {
			if (this.imageCount == this.saveCount) {
				return true;
			}
			return false;
		},
		addEmbed: function (e) {
			var self = this;
			var parent = $(e.currentTarget).parent();
			var id = parent.attr('data-embed-id');
			var embed = $(e.currentTarget).val();
			var iframe = $.parseHTML(embed);
			var width = $(iframe).attr('width');
			var height = $(iframe).attr('height');
			$(this.sections).each(function (i, item) {
				if (item.isEmbed) {
					if (item.embedIndex == id) {
						item.content = embed;
						item.width = width;
						item.height = parseInt(height) + 44;
					}
				}
			});
			this.render();
		},
		injectPositions: function () {
			var imageCount = 0;
			var embedCount = 0;
			$(this.sections).each(function (i, item) {
				item.index = i;
				if (item.isImage) {
					imageCount += 1;
					item.imageIndex = imageCount;
				}
				if (item.isEmbed) {
					embedCount += 1;
					item.embedIndex = embedCount;
				}
			});
		},
		render: function () {
			this.injectPositions();
			var template = Mustache.render(templates.blogBody, { sections: this.sections });
			this.$content.html(template);
			var imageUploadElems = this.$content.find('[data-upload]');
			for (var i = 0; i < imageUploadElems.length; i++) {
				var elem = imageUploadElems[i];
				var index = $(elem).attr('data-image-id');
				var isBound = elem.dataset.isBound;
				if (!isBound) {
					var imageUploader = app.imageUpload({
						returnAPI: true,
					});
					imageUploader.bindImageUpload(elem, index, this.injectSrc.bind(this));
					$(elem).attr('data-is-bound', true);
				}
			}
		},
		init: function () {
			this.cacheDom();
			this.bindEvents();
		},
	};

	return createBlogPostModule.init();
};