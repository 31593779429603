app.templates.cart.items = '\
<div class="{{parentPrefix}}items">\
    {{#items}}\
        <div data-item-id="{{item_id}}" data-product-id="{{product_id}}" class="{{parentPrefix}}item">\
            <div class="{{parentPrefix}}item__image">\
                <div class="{{parentPrefix}}item__remove">\
                    <i class="fa fa-times"></i>\
                </div>\
                <a alt="{{name}}" href="/products/{{slug}}">\
                    <img src="/storage/{{slug}}/{{#lower}}{{colour}}{{/lower}}/cart-image.png"/>\
                </a>\
            </div>\
            <div class="{{parentPrefix}}item__content">\
                <div class="{{parentPrefix}}item__name">{{name}}</div>\
                <div class="{{parentPrefix}}item__price">{{price}}</div>\
                <div class="{{parentPrefix}}item__size">{{size}}</div>\
                <div class="{{parentPrefix}}item__colour">{{colour}}</div>\
                <div class="{{parentPrefix}}item__quantity">\
                    <div class="{{parentPrefix}}item__decrement">\
                        <i class="fa fa-minus"></i>\
                    </div>\
                    <div class="{{parentPrefix}}item__qty">{{quantity}}</div>\
                    <div class="{{parentPrefix}}item__increment">\
                        <i class="fa fa-plus"></i>\
                    </div>\
                </div>\
            </div>\
        </div>\
    {{/items}}\
</div>\
<div class="{{parentPrefix}}loader-container">\
    <div class="{{parentPrefix}}loader"></div>\
</div>';