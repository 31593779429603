app.stickyColumn = function(){
	var stickyColumnModule = {
		cacheDom: function(){
			this.$parent = $('.sticky-container');
			this.$column = $('.checkout-cart');
		},

		setStickVals: function(){
			this.$parentWidth = this.$parent.outerWidth();
			this.$parent.width(this.$parentWidth);
		},

		sticky: function(){
			//30 for paddings/margins
			var windowScroll = $(window).scrollTop(),
				parentPos = this.$column.offset().top - windowScroll,
				parentHeight = this.$column.outerHeight(),
				parentScroll = windowScroll - this.$column.offset().top,
				parentScrollHeight = parseInt(this.$column.outerHeight() - parentScroll),
				columnHeight = this.$parent.outerHeight(),
				maxScrollable = parseInt((parentHeight - columnHeight) + this.$column.offset().top);
			if(parentScrollHeight-30 < columnHeight && this.$column.height() > this.$parent.height()){
				this.$parent.css({
					'position': 'absolute',
					'top': maxScrollable
				});
			}else{
				this.$parent.removeAttr('style');
				this.$parent.width(this.$parentWidth);
				this.$parent.removeClass('sticky-container--sticky');
			}
			if(30 > parentPos && this.$column.height() > this.$parent.height()){
				this.$parent.addClass('sticky-container--sticky');
			}else{
				this.$parent.removeClass('sticky-container--sticky');
			}
		},

		bindEvents: function(){
			$(window).on('scroll', this.sticky.bind(this));
		},

		init: function(){
			this.cacheDom();
			if ($(window).width() > 720) {
				this.setStickVals();
				this.bindEvents();
			}
		}
	};
	return stickyColumnModule.init();
};
// app.stickyColumn();