'use strict';
app.autoComplete = function(parent){
    if($(parent).length > 1){
        $(parent).each(function(){
            var newParent = $(this);
            app.autoComplete(newParent);
        });
        return this;
    }
    var autoCompleteModule = {
        cacheDom: function(){
            this.$parent = $(parent);
            this.$input = this.$parent.find('input[type="text"]');
        },
        getCountries: function(){
            var self = this;
            $.ajax({
                type: 'GET',
                url: '/assets/data/countries.json',
                success: function(data){
                    self.countryList = data;
                }
            });
        },
        setList: function(){
            var self = this,
                matches = [];
            if(this.$input.val().length > 2){
                this.countryList.map(function(data, i){
                    var inputVal = self.$input.val().toLowerCase(),
                        countryName = data.countryName.toLowerCase();
                    if(countryName.indexOf(inputVal) > -1){
                        matches.push(data);
                    }
                });
            }
            var listTemplate = document.createElement('div');
            listTemplate.className = 'autocomplete__list';
            if(matches.length > 0){
                for(var i = 0; matches.length > i; i++){
                    listTemplate.innerHTML += '<span data-id="' + matches[i].countryCode + '" class="autocomplete__item">' + matches[i].countryName + '</span>';
                }
            }else{
                if(this.$input.val().length > 2){
                    listTemplate.innerHTML = '<span class="autocomplete__item autocomplete__item--no-result">No country found</span>';
                }
            }
            this.render(listTemplate);
        },
        calculateListPos: function(){
            this.$list = this.$parent.find('.autocomplete__list');
            var windowHeight = window.innerHeight,
                windowScroll = window.scrollY,
                spaceAbove = this.$list.offset().top - windowScroll,
                spaceBelow = windowHeight - spaceAbove,
                dropDownHeight = this.$list.height(),
                optionsHeight = this.$list.outerHeight();
            if(spaceAbove > spaceBelow){
                this.$list.removeClass('autocomplete__list--top');
                this.$list.addClass('autocomplete__list--bottom');
                this.$list.css({
                    'top': -optionsHeight + 1
                });
            }else{
                this.$list.removeClass('autocomplete__list--bottom');
                this.$list.addClass('autocomplete__list--top');
                this.$list.css({
                    'top': '37px'
                });
            }
        },
        selectOption: function(e){
            var inputVal = e.currentTarget.textContent,
                inputCountryId = e.currentTarget.dataset.id;
            this.$input.val(inputVal);
            $('.autocomplete__list').remove();
            this.$parent.find('[name="country_id"]').val(inputCountryId);
        },
        removeOtherLists: function(){
            $('.autocomplete__list').remove();
        },
        bindEvents: function(){
            this.$input.on('keyup', this.setList.bind(this));
            this.$input.on('click', this.removeOtherLists.bind(this));
            $(document).on('click', '.autocomplete__item', this.selectOption.bind(this));
        },
        init: function(){
            this.cacheDom();
            this.bindEvents();
            this.getCountries();
        },
        render: function(countries){
            if($('.autocomplete__list').length > 0){
                $('.autocomplete__list')[0].innerHTML = countries.innerHTML;
            }else{
                this.$parent.append(countries);
            }
            this.calculateListPos();
        }
    };
    return autoCompleteModule.init();
};
$(function(){
    app.autoComplete('.autocomplete');
});