'use strict';
app.addProduct = function(options) {

    var opts = {
        parent: $('.admin__new-product'),
    };
    
    $.extend(opts, options);

    var addProductModule = {
        cacheDom: function () {
            this.$parent = opts.parent;
            this.$form = this.$parent.find('form');
            this.$submit = this.$parent.find('[type="submit"]');
        },
        bindEvents: function () {
            this.$form.on('submit', this.submit.bind(this));
            this.$form.on('keyup keypress', 'input[type="text"]', this.cancelSubmit.bind(this));
        },
        getToken: function(){
            return $('meta[name="csrf-token"]').attr('content');
        },
        getStore: function () {
            return app.store;
        },
        getImages: function (colour) {
            return app.store.images[colour];
        },
        getVariations: function () {
            return app.store.selectedColours;
        },
        getColours: function () {
            return Object.keys(app.store.images);
        },
        getProduct: function () {
            var variations = this.getVariations();
            for (var i = 0; i < variations.length; i++) {
                var colour = variations[i].name;
                var store = this.getStore();
                variations[i].images = store.images[colour]; 
            }
            app.store.selectedColours = variations;
            return variations;
        },
        addLoader: function () {
            $('.js-global-loader').addClass('loader--active');
        },
        removeLoader: function () {
            $('.js-global-loader').removeClass('loader--active');
        },
        buttonCheck: function () {
            var validation = true;
            for (var i = 0; i < availableFiles.length; i++) {
                if(availableFiles[i].selected === false){
                    validation = false;
                    break;
                }
            }
            if(validation){
                this.$submit.attr('disabled', false);
            }else{
                this.$submit.attr('disabled', true);
            }
        },
        submit: function (e) {
            this.addLoader();
            var self = this;
            var form = new FormData(this.$form);
            var inputs = $('[data-submit]');
            var productData = this.getProduct();
            for (var i = 0; i < inputs.length; i++) {
                form.append(inputs[i].name, inputs[i].value);
            }
            for (var i = 0; i < productData.length; i++) {
                var colour = productData[i].name;
                var images = productData[i].images;
                var sizes = productData[i].selectedSizes;
                for (var x = 0; x < images.length; x++) {
                    form.append('colours['+colour+'][details][name]', productData[i].name);
                    form.append('colours['+colour+'][details][hex]', productData[i].hex);
                    form.append('colours['+colour+'][images]['+x+'][thumb]', images[x].thumb);
                    form.append('colours['+colour+'][images]['+x+'][newName]', images[x].newName);
                    form.append('colours['+colour+'][images]['+x+'][file]', images[x].file);
                }
                for (var x = 0; x < sizes.length; x++) {
                    form.append('colours['+colour+'][sizes]['+x+'][name]', sizes[x].name);
                    form.append('colours['+colour+'][sizes]['+x+'][quantity]', sizes[x].quantity);
                }
            }
            $.ajax({
                url: this.$form[0].action,
                type: 'POST',
                headers: {'X-CSRF-TOKEN': this.getToken()},
                data: form,
                processData: false,
                cache: false,
                contentType: false,
                success: function (message, data) {
                    var baseURL = window.location.origin;
                    window.location.href = baseURL + '/admin/products';
                },
                error: function(err, msg) {
                    console.log(err);
                    console.log(msg);
                }
            });
            return false;
        },
        cancelSubmit: function (e) {
            if(e.which == 13) {
                e.preventDefault();
                return false;
            }
        },
        init: function () {
            this.cacheDom();
            this.bindEvents();
            console.log(this);
        },
    };
    return addProductModule.init();
};