app.tabs = function(){
	var tabsModule = {
		index: 1,
		cacheDom: function(){
			this.$parent = $('.tabbed');
			this.$tabs = this.$parent.find('.tabbed__tab');
			this.$content = this.$parent.find('.tabbed__content');
		},
		changeTab: function(e){
			this.index = $(e.currentTarget).attr('data-tab');
			this.setState();
			this.render();
		},
		setState: function(){
			var tabState = this.$parent.attr('data-tabs'),
				state = {},
				storage = localStorage.getItem('appState'),
				appState = JSON.parse(storage);
			state[tabState+'Tabs'] = this.index;
			if(appState != null || appState != undefined){
				if(tabState != undefined){
					appState[tabState+'Tabs'] = this.index;
					localStorage.setItem('appState', JSON.stringify(appState));
				}
			}else if(tabState !== undefined){
				localStorage.setItem('appState', JSON.stringify(state));
			}
		},
		checkState: function(){
			var storage = localStorage.getItem('appState'),
				appState = JSON.parse(storage),
				tabState = this.$parent.attr('data-tabs');
			if(appState != null || appState != undefined && tabState != undefined){
				if(appState.hasOwnProperty(tabState+'Tabs')){
					this.index = appState[tabState+'Tabs'];
				}
			}
		},
		bindEvents: function(){
			this.$tabs.on('click', this.changeTab.bind(this));
		},
		init: function(){
			this.cacheDom();
			this.bindEvents();
			this.checkState();
			this.render();
		},
		render: function(){
			this.$tabs.removeClass('tabbed__tab--active');
			this.$content.removeClass('tabbed__content--active');
			this.$parent.find('[data-tab="' + this.index + '"]').addClass('tabbed__tab--active');
			this.$parent.find('[data-content="' + this.index + '"]').addClass('tabbed__content--active');
		}
	};
	return tabsModule.init();
};