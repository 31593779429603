app.pages = function(){
	var pagesModule = {
		index: 0,
		spacing: 15,
		cacheDom: function(){
			this.$parent = $('.pages');
			this.$pageWrapper = this.$parent.find('.pages__wrapper');
			this.$pages = this.$parent.find('.pages__page');
			this.$nextButton = this.$parent.find('[data-page-next]');
			this.$prevButton = this.$parent.find('[data-page-prev]');
			this.$pagesCount = this.$pages.length;
			this.$pagesWidth = Math.round(this.$parent.width() - 15/2);
		},
		changeDom: function() {
			this.$pages.outerWidth(this.$pagesWidth);
			this.$pageWrapper.width(this.$pagesWidth * this.$pagesCount);
		},
		checkTarget: function(event) {
			return event.target;
		},
		next: function(e){
			if(this.index == (this.$pagesCount-1)){
				this.$nextButton.attr('data-page-next', 'disabled');
			}else{
				this.$nextButton.attr('data-page-next', '');
				this.index++;
			}
			var target = $(this.checkTarget(e));
			//minus by one if link is clicked
			if(target[0].nodeName === 'A'){
				this.index = this.index - 1;
			}
			this.render();
		},
		prev: function(e){
			if(this.index == 0){
				this.$prevButton.attr('data-page-prev', 'disabled');
			}else{
				this.$prevButton.attr('data-page-prev', '');
				this.index--;
			}
			this.$pages[this.index+1].style.display = 'none';
			this.render();
		},
		bindEvents: function(){
			this.$nextButton.on('click', this.next.bind(this));
			this.$prevButton.on('click', this.prev.bind(this));
		},
		render: function(){
			var movement = this.index * this.$pagesWidth;
			this.$pages[this.index].style.display = 'block';
			this.$pageWrapper.css({
				'transform': 'translate3d(' + -movement + 'px, 0, 0)',
			});
		},
		init: function(){
			this.cacheDom();
			if(this.$parent.length > 0){
				this.changeDom();
				this.bindEvents();
				this.render();
			}
		}
	}
	return pagesModule.init();
}
// app.pages();