app.formValidate = function(parent, callback){
    if($(parent).length > 1){
        $(parent).each(function(){
            var newParent = $(this);
            app.formValidate(newParent);
        });
        return this;
    }
    var defaults = {
        formRowClass: 'form__row',
        formMessageClass: 'form__message',
        formErrorClass: '--error',
        formRowErrorClass: function(){
            return this.formRowClass + this.formErrorClass;
        },
        formMessageErrorClass: function(){
            return this.formMessageClass + this.formErrorClass;
        }
    };
    var formValidateModule = {
        cacheDom: function(){
            this.$form = $(parent);
        },
        removeValidation: function(e){
            var formError = defaults.formRowErrorClass(),
                messageError = defaults.formMessageErrorClass(),
                formMessageErrorClass = '.' + messageError,
                $formRow = $(e.currentTarget);
            $formRow.find(formMessageErrorClass).remove();
            $formRow.removeClass(formError);
        },
        validateForm: function(e){
            this.$requiredFields = this.$form.find('[data-required]');
            var formError = defaults.formRowErrorClass();
            var messageError = defaults.formMessageErrorClass();
            var formErrorClass = '.' + formError;
            var formMessageErrorClass = '.' + messageError;
            var template = '<div class="' + defaults.formMessageClass + ' ' + messageError + '"></div>';
            var error = false;

            this.$requiredFields.off('keyup.inputVal');

            if(callback){
                if(callback.checkBillingForm()){
                    this.$requiredFields = $('[data-card-filler-form]').find('[data-required]');
                }
            }

            for(var i = 0; this.$requiredFields.length > i; i++){
                var $row = $(this.$requiredFields[i]);
                var $input = $row.find('input').length > 0 ? $row.find('input') : $row.find('textarea');
                var title = $row.find('b')[0].innerText;
                var message = title + ' is required';
                if($input.val() == ''){
                    if($row.find(formMessageErrorClass).length > 0){
                        $row.find(formMessageErrorClass).remove();
                    }
                    $row.append(template);
                    $row.find(formMessageErrorClass).html(message);
                    $row.addClass(formError);
                    error = true;
                    this.$requiredFields.on('keyup.inputVal', this.removeValidation.bind(this));
                }
            }

            if(callback && !error){
                if(!callback.formFilled){
                    return callback.stripePayment();
                }
            }

            if(error){
                return false;
            }else{
                this.$requiredFields.removeClass(formError);
                this.$requiredFields.find(formMessageErrorClass).remove();
                if(typeof callback !== 'object'){
                    this.$form.get(0).submit();
                }
            }
        },
        bindEvents: function(){
            this.$form.on('submit', this.validateForm.bind(this));
        },
        init: function(){
            this.cacheDom();
            this.bindEvents();
        }
    }
    return formValidateModule.init();
};